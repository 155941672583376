<template>
	<div class="CaseDetails">
		<div class="header">
			<span class="title1">客户案例</span><span class="arrows">></span><span class="title2">永辉超市</span>
		</div>
		<div class="content">
			<div class="presentation">
				<img src="../../../assets/welfare/2.png" />
				<div class="link">官网链接：</div>
				<div class="link2"><a href="http://www.bonnidee.cn/list/263/1176.html">http://www.bonnidee.cn/list/263/1176.html</a></div>
				<div class="product">使用产品</div>
				<div class="productinfo">5G直播</div>
				<div class="productinfo">5G直播</div>
				<div class="productinfo">5G直播</div>
			</div>
			<div class="contentinfo">
				<Pagination :pagesize="pagesize" :total="total" @chang="handleSizeChange" class="Pagination"></Pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import Pagination from '../../../components/Pagination'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				total: 100,
				pagesize: 10,
			}
		},
		mounted() {

		},
		methods: {
			handleSizeChange(val){
				console.log(val);
			}
		}
	}
</script>

<style scoped lang="less">
	.CaseDetails {
		width: 80%;
		margin: 0 auto;
		padding-top: 20px;

		.header {
			padding: 59px 0;
			font-size: 28px;
			border-bottom: 1px solid #979797;

			.title1 {
				font-family: SourceHanSansCN-Bold, SourceHanSansCN;
				font-weight: bold;
			}

			.arrows {
				color: #BEBEBE;
				padding: 0 25px;
			}

			.title2 {
				font-weight: 500;
				color: #2F7FFC;
			}
		}
		
	
		
		.content{
			display: flex;
			margin-top: 96px;
			.contentinfo{
				box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
				border: 1px solid #E8E8E8;
				padding: 40px 36px;
				margin-left: 36px;
				width: 80%;
				.Pagination{
					text-align: center;
						float: none;
				}
			}
			
			.presentation{
				width: 288px;
				box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
				border: 1px solid #E8E8E8;
				padding: 40px 36px;
				
				.link{
					font-size: 18px;
					font-weight: 500;
					line-height: 27px;
					margin-top: 39px;
				}
				
				img{
					width: 164px;
					height: 49px;
				}
				
				.link2{
					a{
						text-decoration:none;
						color: #007AFF;
						font-size: 14px;
						line-height: 21px;
						font-weight: 500;
					}
					margin-top: 20px;
					padding-bottom: 20px;
					border-bottom: 2px solid #EFEFEF;
				}
				
				.product{
					font-size: 18px;
					font-weight: 500;
					line-height: 27px;
					padding-top: 16px;
					margin-bottom: 20px;
				}
				
				.productinfo{
					font-size: 14px;
					font-weight: 500;
					color: #9E9E9E;
					line-height: 21px;
				}
			}
		}
	}
</style>
